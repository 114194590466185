import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faHandsClapping, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef, useState } from 'react';
import axios from 'axios';

const Chat = () => {
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [messagedata, setMessagedata] = useState([]);
    const [emailpage, setEmailpage] = useState(false)
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null)

    const messgeref = useRef(null);

    const scrolldiv = () => {
        setTimeout(() => {
            messgeref.current?.scrollIntoView({ behavior: 'smooth' })
        }, [1000])
    }

    const scrolldiv2 = () => {
        setTimeout(() => {
            messgeref.current?.scrollIntoView({ behavior: 'smooth' })
        }, [500])
    }

    const Handletextarea = async () => {
        try {
            const href = window.location.href
            const newarray = []
            if (question === null || question.length <= 0) {
                throw new Error('Please enter a question first.')
            }
            const token = localStorage.getItem('chatbot-token');
            if (!token) {
                setEmailpage(true);
                return;
            }
            const obj = { type: 'question', question: question }
            setMessagedata([...messagedata, obj])
            setLoading(true)
            await scrolldiv2()
            setQuestion('')
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const returvalue = await axios.post('http://198.168.1.11:5000/get-answer', { question: question, pageinfo: href });
            newarray.push(obj)
            let answer = returvalue?.data?.data?.ans;
            if (returvalue?.data?.data?.link) {
                newarray.push({ type: 'answer', answer: answer, link: returvalue.data.data.link })
            } else {
                newarray.push({ type: 'answer', answer: answer });
            }
            setLoading(false);
            setMessagedata([...messagedata, ...newarray])
            scrolldiv()
        } catch (err) {
            toast.error(err.message)
            if (err.status === 401) {
                setEmailpage(true);
            }
        }
    }

    const submitTextarea = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            Handletextarea()
        }
    }

    const sendemail = async (e) => {
        e.preventDefault()
        if (!email) {
            setError('Please enter your email.')
            return;
        }
        const response = await axios.post('http://198.168.1.11:5000/create-token', { email: email });
        if (response?.data?.status === true) {
            setEmailpage(false);
            localStorage.setItem('chatbot-token', response.data?.token)
        } else {
            setError(response.msg)
        }
    }

    const hidebutton = async () => {
        try {
            const token = localStorage.getItem('chatbot-token');
            if (!token) {
                setEmailpage(true);
            } else {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const response = await axios.get('http://198.168.1.11:5000/check-token');
                if (response?.data.status === true) {
                    setEmailpage(false)
                }
            }
        } catch (err) {
            setEmailpage(true)
            localStorage.removeItem('chatbot-token');
        } finally {
            document.getElementsByClassName('open-chatbox')[0].style.display = 'none';
            document.getElementsByClassName('main-chat-box')[0].style.display = 'block';
        }
    }

    const hidebox = () => {
        document.getElementsByClassName('open-chatbox')[0].style.display = 'block';
        document.getElementsByClassName('main-chat-box')[0].style.display = 'none';
        setMessagedata([])
        setQuestion('')
    }
    return (
        <>
            <div style={{ width: "100 %", height: "100vh" }} >
                <ToastContainer />
                <div className="Chatboat">
                    <div className='main-chat-box bg-white shadow rounded-4' style={{ display: 'none' }}>
                        <div className="chatboat-header rounded-4 rounded-bottom-0 d-flex align-items-center justify-content-between p-3">
                            <div className='d-flex align-items-center gap-3'>
                                <img src="/images/logo.webp" alt='logo' width="40px" height="40px" className="rounded-pill" />
                                <h5 className='mb-0 text-white'>Hi There</h5>
                            </div>
                            <FontAwesomeIcon type='button' onClick={hidebox} className='text-white fs-5' icon={faChevronDown} />
                        </div>
                        {
                            emailpage ?
                                <div className='chatboat-bottom h-100 pt-4 px-4 pb-2 d-flex flex-column justify-content-between'>
                                    <form method='post' className='d-flex justify-content-center' onSubmit={sendemail}>
                                        <div className='w-100'>
                                            <h5 className="text-dark">Please provide your email address.</h5>
                                            <input className='form-control mt-3 bg-secondary bg-opacity-10' onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' />
                                            {
                                                error && <span className='text-danger fs-14'>{error}</span>
                                            }
                                            <button className='email-btn w-100 mt-3 text-white mb-3' type='submit'>Send</button>
                                        </div>
                                    </form>
                                </div>
                                :
                                <div className='chatboat-bottom h-100 pt-3 px-3 pb-2 d-flex flex-column justify-content-between'>
                                    <div id='message-box' className='position-relative message-box'>
                                        <div className='d-flex'>
                                            <div className='left-box'>
                                                <span className='w-auto'>How can i help you ?</span>
                                            </div>
                                        </div>
                                        {
                                            messagedata && messagedata.map((element, index) => {
                                                return (
                                                    element.type === 'answer' ?
                                                        <div className='d-flex' key={index}>
                                                            <div className='left-box'>
                                                                <span className='w-auto'>
                                                                    {
                                                                        element?.link ?
                                                                            <>
                                                                                {element.answer}
                                                                                <div className="d-flex flex-column mt-3"><span>For more information click here</span> <a href={element.link}>{element.link}</a></div>
                                                                            </>
                                                                            :
                                                                            element.answer
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-flex' key={index}>
                                                            <div className='right-box ms-auto'>
                                                                <span className='w-auto'>{element.question}</span>
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        }
                                        <span className='p-0 m-0' ref={messgeref}></span>
                                    </div>
                                    <div className='w-100 border-top position-relative pt-2'>
                                        <textarea className='chatboat-textarea fs-5 text-secondary rounded-3' value={question} onKeyDown={submitTextarea} onChange={(e) => setQuestion(e.target.value)} placeholder="Enter your message"></textarea>
                                        {
                                            loading ?
                                                <div className='position-absolute send-button shadow'>
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                :
                                                <div className='position-absolute send-button shadow' onClick={Handletextarea}>
                                                    <FontAwesomeIcon icon={faPaperPlane} className='sendpaper' />
                                                </div>
                                        }
                                        <div className='text-center pe-4'>
                                            <span className='fw-semibold fs-12'>
                                                <span className='text-secondary fs-10'>POWERED BY</span> TradeBit
                                            </span>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                    <div className='open-chatbox' style={{ display: 'block' }} onClick={hidebutton}>
                        <button className='btn'>
                            Chat with us <FontAwesomeIcon icon={faHandsClapping} style={{ color: '#cc9a02' }} />
                        </button>
                        <div className='openchatbox-button shadow'>
                            <FontAwesomeIcon icon={faPaperPlane} className='sendpaper' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat;