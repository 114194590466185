import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Chat from './pages/chat';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/chat' element={<Chat />}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
